import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import FullscreenLoader from 'components/Loaders/FullscreenLoader';
import { useUserState } from 'services/userService';
import type { AuthenticationFormV2State } from 'components/AuthenticationFormV2';
import { validateUrl } from 'utils/helpers/validateUrl';
import { usePath, paths } from 'paths';
import { RegularAuth } from './partials';
import * as Styles from './styles';
import camelCase from 'lodash/camelCase';
import { trackArtificialPageView } from 'tracking/helpers';

export default function AuthenticationView() {
  const router = useRouter();
  const pathTo = usePath();
  const [queryEvalComplete, setQueryEvalComplete] = useState(false);
  const { isUserLoggedIn, isUserLoading } = useUserState();

  const [formType, setFormType] = useState<AuthenticationFormV2State>('email');

  useEffect(() => {
    if (!router.isReady) return;

    setQueryEvalComplete(true);
  }, [router.query]);

  useEffect(() => {
    if (isUserLoggedIn) {
      router.push(getRedirectUrl(router.query.redirect as string));
    }
  }, [isUserLoggedIn]);

  function getRedirectUrl(url?: string) {
    if (typeof url === 'string') {
      return url.startsWith('http') ? validateUrl(url, pathTo) : `${pathTo(url)}`;
    } else {
      return `${pathTo(paths.userProfile)}`;
    }
  }

  useEffect(() => {
    if (queryEvalComplete) {
      trackFormChange(formType);
    }
  }, [queryEvalComplete]);

  function trackFormChange(formType: AuthenticationFormV2State) {
    trackArtificialPageView(`authPage${camelCase(formType).replace(/./, c => c.toUpperCase())}`);
  }

  function handleFormChange(formType: AuthenticationFormV2State) {
    setFormType(formType);
    trackFormChange(formType);
  }

  const showLoader = isUserLoggedIn || isUserLoading;

  return (
    <>
      {showLoader ? (
        <>
          <FullscreenLoader />
        </>
      ) : (
        <Styles.ContentWrapper data-cs-signal-enabled>
          {!isUserLoggedIn && queryEvalComplete && <RegularAuth formType={formType} onFormChange={handleFormChange} />}
        </Styles.ContentWrapper>
      )}
    </>
  );
}
