import { defineMessages } from 'react-intl';

const messages = defineMessages({
  restoreCartIntro: {
    defaultMessage: 'Welcome back. Please log in to access your cart.',
    id: 'auth.restoreCart.intro'
  }
});

export default messages;
