import React from 'react';
import { useRouter } from 'next/router';
import type { AuthenticationFormV2State } from 'components/AuthenticationFormV2';
import AuthenticationFormV2 from 'components/AuthenticationFormV2';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

type Props = {
  formType: AuthenticationFormV2State;
  onFormChange: (state: AuthenticationFormV2State) => void;
};

export default function RegularAuth({ formType, onFormChange }: Props) {
  const router = useRouter();

  const introCopyLookup = {
    cart: <FormattedMessage {...messages.restoreCartIntro} />
  };

  const introCopy = typeof router.query?.type === 'string' && introCopyLookup[router.query.type];

  return (
    <AuthenticationFormV2
      authenticationType={formType}
      onFormChange={onFormChange}
      disableNotificationSuccess
      introCopy={introCopy}
    />
  );
}
