import React from 'react';
import AuthenticationView from 'views/AuthenticationView';
import retrieveFromContentfulCache from 'utils/buildCache/retrieveFromContentfulCache';

import CustomHead from 'blocks/CustomHead';
import authenticationMessages from 'messages/authentication';
import { PageProps } from 'types/page';
import { useIntl } from 'react-intl';
import { StaticProps, Services } from 'services/context';
import { getLocale } from 'services/locale';
import retrieveFromLocaleCache from 'utils/buildCache/retrieveFromLocaleCache';

export default function Authentication(props: any) {
  const intl = useIntl();

  return (
    <>
      <CustomHead title={intl.formatMessage(authenticationMessages.login)} />
      <AuthenticationView {...props} authenticationType='login' />
    </>
  );
}

type GetStaticProps = StaticProps<Services>;

export async function getStaticProps(props: PageProps): Promise<GetStaticProps> {
  const locale = getLocale(props.locale);

  if (!locale) {
    return {
      notFound: true
    };
  }

  const [pageLayoutData, webStore, messages] = await Promise.all([
    retrieveFromContentfulCache('pageLayoutData', locale),
    retrieveFromContentfulCache('webStore', locale),
    retrieveFromLocaleCache(locale)
  ]);

  return {
    props: {
      footer: pageLayoutData.footer,
      locale,
      menuNavigation: pageLayoutData.menu,
      messages,
      pageName: 'login',
      webStore
    }
  };
}
