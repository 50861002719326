import styled from 'styled-components';
import { breakpointRules, grid } from '@aceandtate/ds';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding: ${grid[24]};
  padding-bottom: 50%;
  position: relative;
  text-align: center;
  width: 100%;

  @media ${breakpointRules.tablet} {
    max-width: 760px;
    padding: ${grid[40]};
    padding-bottom: 50%;
  }

  form {
    padding-top: 40px;
    width: 100%;
  }

  .back-button-auth-form {
    position: absolute;
    top: ${grid[24]};

    @media ${breakpointRules.tablet} {
      left: ${grid[40]};
      margin-top: ${grid[12]};
      top: ${grid[40]};
    }
  }
`;
